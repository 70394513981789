import React from "react";

const SliderBar = () => {
  return (
    <div className="sliderBar">
      <div className="icon"></div>
    </div>
  );
};

export default SliderBar;
